import React from 'react';
import Logo from '../svg/outlineLogo.svg';
import { graphql } from 'gatsby';
import ProductGrid from '../components/ProductGrid';
import Seo from '../components/SEO';
import loadable from '@loadable/component';
import { GatsbyImage } from 'gatsby-plugin-image';
import ValueBloc from '../components/ValueBloc';
import SustainabilityBloc from '../components/SustainabilityBloc';

const Insta = loadable(() => import('../components/Insta'));
const ReactPlayer = loadable(() => import('react-player/youtube'));

// import { ThemeProvider } from './components/Theme/ThemeContext';
// import Background from './components/Theme/Background';
// import Toggle from './components/Theme/ThemeToggle';

function IndexPage({ data: { products, hero, social } }) {
  return (
    <section className="mt-16 text-primary md:mt-20 lg:mt-12">
      <Seo />

      <div className="flex flex-col justify-center flex-grow border lg:flex-row border-primary">
        <div className="flex flex-col justify-between p-8 font-serif text-5xl font-extrabold md:p-16 lg:w-2/4">
          <h1>
            design—led <br /> eco—friendly <br />
            clothing co.
          </h1>
          <div className="flex flex-col justify-end pt-8 h-2/4">
            <Logo />
          </div>
        </div>
        <div className="border-t border-primary lg:w-2/4 md:border-l-0 lg:border-t-0 lg:border-l md:mr-px ">
          <GatsbyImage
            className="h-full"
            alt="hero image"
            image={hero.childImageSharp.gatsbyImageData}
          />
        </div>
      </div>
      <section className="flex flex-col justify-center flex-auto flex-grow -mt-px border border-primary">
        <div className="flex flex-col sm:flex-row">
          <div
            className="p-8 lg:w-2/4 md:p-16"
            style={{ marginRight: '-.5px' }}
          >
            <h2 className="text-2xl font-bold">our ethos</h2>
            <p className="pt-2 text-sm">
              create timeless pieces that are sourced responsibly through
              eco—friendly and sustainable means
            </p>
          </div>
          <div className="p-8 border-t lg:w-2/4 md:p-16 sm:border-t-0 sm:border-l border-primary">
            <h3 className="font-serif text-2xl font-bold text-left lg:text-right">
              london / newcastle
            </h3>
          </div>
        </div>
      </section>
      <section className="-mt-px">
        <ProductGrid products={products.nodes.slice(6, 20)} />
        <div className="-mt-px">
          <ProductGrid products={products.nodes.slice(0, 6)} />
        </div>
        <div className="-mt-px border border-b player-wrapper border-primary">
          <ReactPlayer
            className="react-player"
            url="https://youtu.be/IV-9EJrCH_w"
            width="100%"
            height="100%"
          />
        </div>

        <div className="-mt-px ">
          <ValueBloc />
          {/* <SustainabilityBloc /> */}
        </div>
        <Insta />
      </section>
      <section>
        <p className="h-screen py-64 mt-48 font-serif text-4xl font-bold text-center text-primary">
          thank you for joining <br /> us on this journey
        </p>
      </section>
    </section>
  );
}
export const pageQuery = graphql`
  query IndexQuery {
    hero: file(relativePath: { eq: "gulsah.png" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          formats: [AUTO]
        )
      }
    }
    social: file(relativePath: { eq: "socialCard.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: BLURRED
          formats: [AUTO]
        )
      }
    }
    products: allGraphCmsProduct {
      nodes {
        id
        name
        price
        organic
        inStock
        newIn

        images {
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO]
              )
            }
          }
        }
        remoteId
      }
    }
  }
`;

export default IndexPage;
