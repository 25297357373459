import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

function Sustainability() {
  const image = useStaticQuery(graphql`
    query {
      farm: file(relativePath: { eq: "farm.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            formats: [AUTO]
          )
        }
      }
    }
  `);
  return (
    <section className="-my-px">
      <div className="flex flex-col justify-center flex-grow border lg:flex-row border-primary">
        <div className="flex flex-col justify-between p-8 lg:w-2/4 lg:p-16">
          <div className="flex flex-col justify-between h-full">
            <div>
              <h4 className="pb-4 font-serif text-4xl font-bold">
                our sustainability efforts
              </h4>
              <p className="pb-16 text-base">
                check out what we are doing in the ways for sustainability —
                we've even made a lil video about it.
              </p>
            </div>
            <Link className="secondary-btn" to="/sustainability">
              learn more
            </Link>
          </div>
        </div>
        <div className="border-t border-primary lg:w-2/4 md:border-l-0 lg:border-t-0 lg:border-l">
          <GatsbyImage
            image={image.farm.childImageSharp.gatsbyImageData}
            alt="wind farm"
            className="h-full"
          />
        </div>
      </div>
    </section>
  );
}

export default Sustainability;
